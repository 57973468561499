<template>
  <div class="login-container">
<!--    <div class="left-side-bar"></div>-->
    <div class="login-container__content">
      <app-bar @sendMail="sendMail()" />
        <slot />
    </div>
  </div>
</template>

<script>
import AppBar from "@/components/modules/AppBar";
export default {
  name: "AuthorizationWrapper",
  components: {AppBar},
  methods : {
    sendMail() {
      console.log('send mail')
    }
  }
}
</script>

<style scoped lang="scss">
.login-container {
  min-height: 100vh;
  display: flex;
  flex: 1 1 auto;

  &__content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    transition: all .3s;
  }
}
</style>
