<template>
    <div class="request-password">
        <div class="form-container">
            <h2>Восстановление пароля</h2>
            <form @submit.prevent="submit">
                <rwm-text-field
                        class="login"
                        v-model="form.phone"
                        width="100%"
                        label="+7 ххх ххх хх хх"
                        v-mask="'+7 ### ### ## ##'"
                        :error="errors.phone || error"
                        @blur="validate('phone')"
                        @focus="$emit('clearError')"
                />
                <rwm-text-field
                        class="email"
                        v-model="form.email"
                        width="100%"
                        label="email@email.com"
                        :error="errors.email || error"
                        @blur="validate('email')"
                        @focus="$emit('clearError')"
                />
                <rwm-button width="100%" type="submit">Запросить смену пароля</rwm-button>
            </form>
        </div>
    </div>
</template>

<script>
    import RwmTextField from "../RWMTextField/RwmTextField";
    import RwmButton from "../RWMButton/RwmButton";
    export default {
        name: "RequestPassword",
        components: {RwmButton, RwmTextField},
        props: {
            error: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                form: {
                    phone: "",
                    email: ""
                },
                errors: {
                    phone: false,
                    email: false
                }
            }
        },
        methods: {
            submit() {
                if (this.isValid()) {
                    let {phone, email} = this.form
                    this.$emit('submit', {phone: phone, email: email})
                }
            },
            isValid() {
                let valid = false
                for (let form in this.form) {
                    this.validate(form)
                }
                for (let err in this.errors) {
                    if (this.errors[err]) {
                        valid = true
                    }
                }
                return !valid
            },
            validate(param) {
                this.form[param].length === 0 ? this.errors[param] = true : this.errors[param] = false
                if (param.match('phone')) {
                    let phone = /^\+7[\d() -]{4,14}\d$/
                    phone.test(this.form[param]) ? this.errors[param] = false : this.errors[param] = true
                }
                if (param.match('email')) {
                    let mail = /^[\w]{1}[\w-.]*@[\w-]+\.[a-z]{2,4}$/i
                    mail.test(this.form[param]) ? this.errors[param] = false : this.errors[param] = true
                }

            }
        }
    }
</script>

<style lang="scss">
.request-password {
    flex: 1 1 auto;
    padding: 60px 42px 0 42px;
    text-align: left;
    @media screen and (min-width: 961px){
        padding: 96px 0 0;
    }
    & .form-container {
        width: 552px;
        padding: 96px;
        margin: auto;
        box-sizing: border-box;
        border: 2px solid #E5EAEE;
        border-radius: 15px;
        & h2 {
            margin-bottom: 32px;
            color: $RWM-dark;
        }
        & form {
            & .login,
            & .email {
                margin-bottom: 32px;
            }
        }
    }
}
</style>