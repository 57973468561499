<template>
  <authorization-wrapper>
    <request-password
      :error="error"
      @submit="requireReset"
      @clearError="clearError()"
    />
  </authorization-wrapper>
</template>

<script>
import AuthorizationWrapper from "../components/modules/AuthorizationWrapper";
import RequestPassword from "../components/pages/RequestPassword";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "PasswordRequestView",
  components: { RequestPassword, AuthorizationWrapper },
  data() {
    return {
      error: false,
    };
  },
  computed: {
    ...mapGetters("errorModule", ["getERROR"]),
  },
  methods: {
    ...mapActions("passwordActionsModule", ["REQUIRE_RESET"]),
    ...mapActions("AuthModule", ["LOGOUT"]),
    clearError() {
      this.error = false;
    },
    requireReset(form) {
      this.LOGOUT();
      let refForm = { ...form };
      refForm.phone = refForm.phone.replace(/\D/g, "");
      this.REQUIRE_RESET(refForm).then((answer) => {
        if (!answer) {
          this.error = this.getERROR?.length > 0;
          if (this.error) {
            // open pop up
            this.$toast.error(
              "Телефон или email не совпадают с регистрационными данными"
            );
          }
        } else {
          this.$toast.info(
            "На вашу почту была отправлена ссылка на смену пароля"
          );
          setTimeout(() => {
            this.$router.push("/login");
          }, 4000);
        }
      });
    },
  },
};
</script>

<style lang="scss"></style>
